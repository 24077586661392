@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Praise&family=Roboto:wght@100&display=swap');

body {
    padding: 0 !important;
}

.wrapper {
    max-width: 1440px !important;
    margin: 0 auto !important;
}

.devel-banner {
    position: fixed;
    font-weight: bold;
    font-family: system-ui;
    bottom: 40px;
    right: -1px;
    color: white;
    background-color: #c30000;
    box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
    border-radius: 3px 3px 3px 3px;
    font-size: 12px;
    padding: 5px 10px;
}

.navbar-fixed-1 {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: #f8f8f8 !important;
    border: 0 !important;
}

.navbar-fixed-2 {
    position: sticky;
    width: 100%;
    top: 96px;
    z-index: 998;
    background-color: #f8f8f8 !important;

    .header-navbar {
        border-radius: 0.428rem;
    }
}

html body .app-content {
    padding-top: 1.5rem !important;
}

.notyf__toast {
    max-width: 450px !important;
}

.notyf__ripple {
    width: 600px !important;
}

.notify-not-validated {
    max-width: 490px !important;

    a {
        color: #0e0a0a !important;
        cursor: pointer;
    }
}

.notify-cookies {
    max-width: none !important;
    background-color: rgb(0, 123, 255);

    .notyf__message {
        text-align: justify !important;
    }

    a {
        color: #00ff89;
        cursor: pointer;
    }
}

.auth-v1 {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh)*100);
    width: 100%;

    label {
        font-size: 14px !important;
    }

    small {
        font-size: 13px;
    }

    .auth-inner {
        width: 400px;

        .card {
            border: 1px solid #80808033;
        }

        .card-title{
            text-align: center;
        }

        .card-text {
            text-align: center;
        }
    }
}

.text-justify {
    text-align: justify;
}

.w-10 {
    width: 10%!important;
}

.brand-text-black {
    font-family: 'Permanent Marker', cursive;
    color: #000000c2;
}

.pe-5px {
    padding-right: 6px !important;
}

.ps-5px {
    padding-left: 6px !important;
}

.brand-svg-auth {
    width: 130px;
}

html body {
    min-width: 310px !important;
}

.input-help-block {
    margin-top: 5px;
    padding-left: 3px;
    padding-right: 3px;
}

.language-link-highlight {
    border-top: 1px solid #a7a7a7;
    border-bottom: 1px solid #a7a7a7;
}

.cursor-default {
    cursor: default;
}

.invalid-remote {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .857rem;
    color: #EA5455;
}

.h_agree_check {
    display:none !important;
}

.vertical-line {
    border-left: 1px solid #a5a5a5;
    height: 40px;
    padding-left: 3px;
    padding-right: 3px;
}

.fix-icon-button {
    line-height: 16px;
}

.menu-content {
    background: #f8f8f8;
    height: 160px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 980;
}

.avatar.bg-light-photovoltaic {
    color: #FF9F43!important;
}

.bg-light-photovoltaic {
    background: rgba(255,159,67,.12)!important;
}

.avatar.bg-light-thermal {
    color: #ffc107 !important;
}

.bg-light-thermal {
    background: #ffc10729 !important;
}

.avatar.bg-light-water {
    color: #0e7b93 !important;
}

.bg-light-water {
    background: #d0f3ff !important;
}

.dropdown-item.active {
    color: #7367F0 !important;
    background-color: rgba(115,103,240,.12) !important;
}

.daterangepicker {
    z-index: 900 !important;
}

.dropdown-menu {
    z-index: 800;
}

hr:not([size]) {
    height: 0 !important;
}

.ant-btn:active {
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.85) !important;
}

.time-card-wrapper {
    .ant-btn-group button:first-child{
        cursor: default !important;
        border-color: #d9d9d9 !important;
        color: rgba(0, 0, 0, 0.85) !important;
    }
}

.dropdown-item-small {
    font-size: 13px !important;
}

.invalid-feedback-always {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .857rem;
    color: #EA5455;
}

.from-label-size-14 {
    label {
        font-size: 14px !important;
    }
}

.card {
    hr {
        height: 1px !important;
    }
}

.progress-bar-solar-thermal {
    background-color: rgba(250, 193, 8, 12%);
    .progress-bar {
        background-color: rgba(250, 193, 8, 100%);
    }
}

.cursor-pointer {
    cursor: pointer;
}

.apexcharts-menu {
    width: 135px !important;
}

.card-statistics {
    .card-header {
        padding: 1.5rem !important;
    }

    .statistics-body {
        padding: 0 2.4rem 2.8rem !important;
    }
}

.avatar-lg {
    width: 60px !important;
    height: 60px !important;

    svg {
        width: 30px !important;
        height: 30px !important;
    }
}

.avatar-lg {
    .avatar-lg-icon-small {
        width: 25px !important;
        height: 25px !important;
    }
}

.avatar {
    .avatar-legend-icon {
        width: 15px !important;
        height: 15px !important;
    }
}

.badge-icon-lg {
    svg {
        width: 20px !important;
        height: 20px !important;
        stroke-width: 2px !important;
    }
}

.badge-icon-no-stroke {
    svg {
        stroke-width: 0 !important;
    }
}

.table-row-menu {
    svg {
        cursor: pointer;
        stroke-width: 2px !important;
        width: 16px !important;
        height: 16px !important;
    }

    .icon-me {
        margin-right: 5px !important;
    }
}

.site-input-group-wrapper .site-input-split {
    background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
    border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
    border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
    border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
    border-right-width: 1px;
}

.ant-tag-close-icon {
    svg {
        vertical-align: initial !important;
    }
}

.grid-form {
    .form-label, .ant-tag {
        font-size: 13px !important;
    }
}

.react-dataTable {
    .loading-bar-container {
        position: relative !important;

        .loading-bar {
            div {
                box-shadow: none !important;
            }
        }
    }
}

.select__control--is-focused {
    border-color: #7367f0 !important;
    box-shadow: 0 0 0 0 !important;
}

.is-invalid {
    .select__control {
        border-color: #ea5455 !important;
    }
}

.rdt_TableCell {
    padding: 0.72rem 1em !important;
    min-width: 16px !important;
}

.rdt_TableCol {
    padding: 0.72rem 1em !important;
    min-width: 16px !important;
}

.legend-wrapper {
    margin-left: 15px;
    margin-right: 15px;

    .legend {
        cursor: pointer;
        display: flex;

        .label {
            color: rgb(55, 61, 63);
            font-size: 12px;
            font-weight: 400;
            font-family: Helvetica, Arial, sans-serif;
            white-space: nowrap;
            margin-bottom: 0 !important;
        }
    }
}


.legend-deselect {
    opacity: 0.5;
}

.avatar-no-background {
   background: transparent !important;
}

.chartjs-container {
    position: relative;
    /*margin: auto;*/
}

.chartjs-legend-li {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 5px;

    .chartjs-label-text {
        margin: 0;
        padding: 0;
        font-size: 11px;
        white-space: nowrap;
    }

    .chartjs-label-icon {
        margin-top: -6px;
    }
}

.grey-apex-charts-tooltip {
    .apexcharts-tooltip {
        background: rgba(34,34,34,0.83) !important;
        color: #fff !important;
        border-radius: 3px !important;
        padding: 5px !important;
        border: 1px solid black !important;
        margin: 0 !important;
        font: normal 12px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important;
    }
}

.rd-mobile-dropdown {
    .dropdown-menu {
        width: 200px;
    }
}

.dropdown-toggle::after {
    content: none !important;
}

.rd-dropdown {
    .rd-dropdown-toggle-white {
        color: #6e6b7b !important;
        background-color: white !important;
        border-radius: 0 !important;
        border: none !important;

    }

    .dropdown-toggle {
        padding-right: 10px !important;
        padding-left: 10px !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .rd-dropdown-toggle-white:hover {
        box-shadow: none !important;
    }

    .rd-dropdown-toggle-pl-5 {
        padding-left: 5px !important;
    }

    .rd-dropdown-toggle-pl-0 {
        padding-left: 0 !important;
    }

    .dropdown-menu {

        @media (max-width:768px) {
            top: 60px !important;
        }

        @media (min-width:768px) {
            top: 50px !important;
        }

        .dropdown-item { /* button */
            width: 100% !important;
            color: #6e6b7b !important;
            padding: 0 !important;

            a {
                padding-right: 10px !important;
                padding-left: 10px !important;
                padding-top: 6px !important;
                padding-bottom: 6px !important;
                width: 100% !important;
                color: #6e6b7b !important;
            }

            a:hover {
                color: #6d62e4 !important;
            }

            a:focus {
                color: #6d62e4 !important;
            }

            a:active {
                color: white !important;
            }



        }

        //button:hover {
        //    color: #fff;
        //    text-decoration: none;
        //    background-color: #7367f0;
        //}

        .dropdown-item.disabled {
            a {
                color: #9e9e9e !important;
            }
        }
    }
}

.bg-approval-offer {
    background: rgb(155 155 155 / 12%) !important;
    color: #939393 !important;
}

//START :: RESPONSIVE FIXES

//NAVBAR MENU POSITION ON CLICK
@media (max-width: 767.98px) {
    .header-navbar .navbar-container .show ~ .dropdown-menu {
        top: 75px !important;
        margin-top: 0 !important;
    }
}

@media (min-width: 767.98px) {
    .header-navbar .navbar-container .show ~ .dropdown-menu {
        top: 48px !important;
    }
}

//auto display menu navbar
/*@media all and (min-width: 992px) {
    .dropdown-on-hover .nav-item .dropdown-menu{ display: none; }
    .dropdown-on-hover .nav-item:hover .nav-link{   }
    .dropdown-on-hover .nav-item:hover .dropdown-menu{ display: block; }
    .dropdown-on-hover .nav-item .dropdown-menu{ top: 48px !important; }
}*/

@media(max-width: 480px) {
    .notyf__toast--dismissible .notyf__wrapper {
        padding-right: 55px;
    }
}

//MENU TOGGLE TO HAMBUGUER
.collapsed-menu {
    display: none;
}


#google-maps-container {
    padding: 0 !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
}

@media (max-width: 576px) and (min-width: 335px) {
    .col-xxs-6-335 {
        flex: 0 0 auto;
        width: 50%; }
}

@media (max-width: 575px) {
    .hide-576 {
        display: none !important;
    }
}

@media (max-width: 589px) {
    .hide-590 {
        display: none !important;
    }
}

@media (max-width: 660px) {
    .inline-menu {
        display: none;
    }
    .collapsed-menu {
        display: inline;
    }
}

@media (max-width: 991px) {
    .hide-992 {
        display: none !important;
    }
}

.show-400 {
    display: none !important;
}

.show-450 {
    display: none !important;
}

@media (max-width: 400px) {
    .hide-400 {
        display: none !important;
    }

    .show-400 {
        display: inline !important;
    }
}

@media (max-width: 450px) {
    .hide-450 {
        display: none !important;
    }

    .show-450 {
        display: inline !important;
    }
}

@media (max-width: 1199px) {
    .hide-1200 {
        display: none !important;
    }
}

@media (max-width: 1439px) {
    .hide-1440 {
        display: none !important;
    }
}

@media (min-width: 1439px) {
    .show-1440 {
        display: none !important;
    }
}

@media (max-width: 648px) {
    .content-header .breadcrumb {
        display: inline-flex !important;
    }
}

@media (min-width: 576px) and (max-width: 730px) {
    .center-576-730 {
        margin: 0 auto;
    }

    .hide-576-730 {
        display: none !important;
    }
}

.show-450-575 {
    display: none !important;
}

@media (min-width: 450px) and (max-width: 575px) {
    .hide-450-575 {
        display: none !important;
    }

    .show-450-575 {
        display: inline !important;
    }
}

@media (min-width: 576px) {
    .show-576 {
        display: inline !important;
    }
}

@media (min-width: 784px) and (max-width: 1440px) {
    .hide-784-1440 {
        display: none !important;
    }

    .show-784-1440 {
        display: inline !important;
    }
}

@media (min-width: 992px) and (max-width: 1440px) {
    .hide-992-1440 {
        display: none !important;
    }

    .show-992-1440 {
        display: inline !important;
    }
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .row {
        .col-1439-1200-30c {
            width: 30% !important;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .row {
        .col-992-1199-40c {
            width: 40% !important;
        }
    }
}

@media (min-width: 0px) and (max-width: 360px) {
    .hide-360 {
        display: none !important;
    }

    .mb-10-360 {
        margin-bottom: 10px;
    }
}

/* las modifications: apply on smartphones */
@media (max-width:768px) {
    .card-mbl {
        border-left: 0 !important;
        border-right: 0 !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        border-top: 0 !important;
        margin-bottom: 0 !important;

        .card-body-mbl {
            //padding-top: 10px !important;
            border-top: 1px #e8e8e8 solid !important;
            background-color: white !important;
        }

        .card-body-map-mbl {
            padding:  20px 20px 20px 20px !important;
        }

        #google-maps-container {
            padding-top: 20px !important;
        }

        .pl-0-mbl {
            padding-left: 0 !important;
        }

        .pr-0-mbl {
            padding-right: 0 !important;
        }

        .pt-20-mbl {
            padding-top: 20px !important;
        }

        .pl-5-mbl {
            padding-left: 5px !important;
        }

        .pr-5-mbl {
            padding-right: 5px !important;
        }

        .pr-10-mbl {
            padding-right: 10px !important;
        }

        .pb-0-mbl {
            padding-bottom: 0 !important;
        }
    }

    .auth-v1-mbl {
        //align-items: center;
        //justify-content: center;
        //overflow: hidden;
        //display: flex;
        //flex-basis: 100%;
        //min-height: 100vh;
        //min-height: calc(var(--vh, 1vh)*100);
        //width: 100%;
        align-items: unset !important;
        //justify-content: center !important;
        //display: block !important;
        //flex-basis: unset !important;
        //min-height: unset !important;
        //width: unset !important;
        //max-width: 500px !important;
    }

    .navbar-fixed-2-mbl {
        top: 0 !important;

        .menu-border-mbl {
            border-top: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
            border-radius: 0 !important;
        }
    }

    .row-mbl {
        all: unset !important;

        .col-mbl {
            all: unset !important;
        }
    }

    .match-height-mbl {
        all: unset !important;
    }
}
//END :: RESPONSIVE FIXES
