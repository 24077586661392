@keyframes beat {
    0%, 50%, 100% { transform: scale(1, 1); }
    30%, 80% { transform: scale(0.92, 0.95); }
}

#loading-mask{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:20000;
    /*background-color:white;*/
}
#loading{
    position:absolute;
    height: 100%; width:100%;
    display: table;
    z-index:20005;
    /*font-family: 'Roboto', sans-serif;*/
}
#loading a {
    color:#225588;
}
#loading .loading-indicator{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align:center;
}
#loading .loading-indicator .text {
    /*font-size: 16px;*/
    margin-bottom: 13px;
}
#loading .loading-indicator .text-dynamic {
    /*font-size: 20px;*/
    margin-top: 20px;
}

#loading #loading-image {
    width: 150px;
}

#loading .loading-image-animate {
    animation: 2.5s ease 0s infinite beat;
}