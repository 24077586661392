@import 'variables';
@import 'bootstrap-extended.min.css';
@import 'bootstrap.css';

.react-dataTable {
  border-radius: 0;
  .rdt_TableHead {
    .rdt_TableHeadRow {
      background-color: $table-head-bg;
      border-top: 1px solid;
      border-color: $border-color;
      min-height: 0;
      height: 38px;

      .rdt_TableCol {
        padding: 0.72rem 1.5rem;
        .rdt_TableCol_Sortable {
          color: $body-color;
          font-weight: bold;
          font-size: 0.857rem;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          &:hover {
            color: $body-color;
          }
        }
      }

    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        height: 53px;
        padding: 0.72rem 1.5rem;

        .column-action {
          svg {
            stroke: $body-color;
          }

          .dropdown-item:hover {
            svg {
              stroke: $primary;
            }
          }

          .dropdown-item:active {
            svg {
              stroke: $white;
            }
          }
        }
      }
    }
  }

  .react-paginate {
    &.separated-pagination {
      .page-item:first-child .page-link,
      .page-item:last-child .page-link {
        border-radius: 50%;
      }
    }
  }
}

.rdt_Pagination {
  select {
    min-width: 30px;
    &:focus,
    &:active {
      outline: 0;
    }
  }
}

.dataTable-filter {
  max-width: 220px;
}

.dataTable-select {
  width: 5rem;
  &:not([multiple='multiple']) {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    background-position: calc(100% - 12px) 10px, calc(100% - 20px) 12px, 100% 0;
    background-size: 10px 10px, 10px 10px;
  }
}